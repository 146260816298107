<mat-drawer-container class="chat-container">
    <mat-drawer #conversationList [mode]="drawerMode" [opened]="conversationDrawerState$ | async" class="conversation-list">
        <div class="conversation-header">
            <div style="vertical-align: middle;">{{"CONV.TITLE" | translate}}</div>
            <div>
                <app-add-conversation-button [disabled]="(appBusyState$ | async) || false"></app-add-conversation-button>
            </div>
        </div>
        <mat-button-toggle-group class="conversation-filter" [(ngModel)]="visibilityFilter" *ngIf="((conversations$ |async) || [] | filterConversations:'public').length > 0">
            <mat-button-toggle value="public" class="conversation-filter-button">{{'CONV.PUBLIC' | translate}}</mat-button-toggle>
            <mat-button-toggle value="private" class="conversation-filter-button">{{'CONV.PRIVATE' | translate}}</mat-button-toggle>
            <mat-button-toggle value="all" class="conversation-filter-button">{{'CONV.ALL' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-nav-list>
            @for (conversation of ((conversations$ |async) || [] | orderConversationBy :'modified_at':'desc'); track conversation.id; let idx = $index) {
                <mat-list-item *ngIf="visibilityFilter == 'all' || visibilityFilter == conversation.visibility || (activeConversationId$ | async) == conversation.id" [id]="'conv' + conversation.id" [activated]="(activeConversationId$ | async) == conversation.id" routerLink="../../conversation/{{conversation.id}}">
                        <div matListItemTitle>{{conversation.title || "CONV.UNTITLED" | translate}}</div>
                       <p matListItemLine>{{conversation.modified_at | dateAgo | async }}</p>
                    <p matListItemMeta>
                        <button *ngIf="(activeConversationId$ | async) == conversation.id" mat-icon-button style="--mat-icon-button-state-layer-color: none;" [disableRipple]="false" [matMenuTriggerFor]="conversationMenu" [matMenuTriggerData]="{id: conversation.id, title: conversation.title}" [matMenuTriggerRestoreFocus]="false"><mat-icon>more_vert</mat-icon></button>
                        <mat-icon *ngIf="conversation.visibility == 'public'" [matTooltip]="'CONV.PUBLIC_TOOLTIP' | translate" class="conversation-visibility-icon">groups</mat-icon>
                    </p>
                </mat-list-item>
            }
            <mat-menu #conversationMenu="matMenu">
                <ng-template matMenuContent let-id="id" let-title="title">
                    <button mat-menu-item (click)="editTitle($event, id, title);"><mat-icon>edit</mat-icon>
                        {{ 'CONV.EDIT_TITLE' | translate }}</button>
                    <button mat-menu-item (click)="deleteConversation($event, id)"><mat-icon class="delete-icon">delete</mat-icon>
                        {{ 'CONV.DELETE' | translate }}</button>
                </ng-template>
            </mat-menu>
        </mat-nav-list>

        <div class="feedback-container"><button mat-fab (click)="openFeedbackPanel()">
            <mat-icon fontSet="material-icons-outlined">feedback</mat-icon>
        </button></div>
    </mat-drawer>
    <mat-drawer-content class="chat-panel">
        <div class="scroll-container" #chatScrollContainer>
            <div class="chat-conversation" #chatMessages>
                <mat-card  appearance="outlined" class="chat-llm-answer-card" *ngIf="(activeConversationId$ | async) && ((conversationDetail$ | async)?.messages || []).length == 0">
                    <mat-card-content class="chat-llm-answer">
                        <div markdown [ngClass]="intro_class" [data] ="'CONV.INTRO' | translate">
                        </div>
                        <div class="chat-llm-answer-controls">
                            <div class="control-copy"></div>
                            <div class="control-streaming"></div>
                            <div class="control-sources"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
                @for (element of (conversationDetail$ |async)?.messages; track elementKey(element);) {
                    <mat-card  appearance="outlined" class="chat-user-prompt">
                    <mat-card-content>
                        <p markdown>{{element.question}}</p>
                    </mat-card-content>
                </mat-card>
                    <mat-card  appearance="outlined" class="chat-llm-answer-card" *ngIf="!element.ongoing">
                        <mat-card-content class="chat-llm-answer">
                            <markdown katex  [katexOptions]="katexOptions" class="chat-llm-answer-text" >
                                {{sanitizeContent(element.answer)}}


                            </markdown>
                            <div class="chat-llm-answer-controls">
                                <div class="control-copy" ><button mat-icon-button class="control-copy-button" (click)="copyClipboard(element.answer)"><mat-icon>content_copy</mat-icon></button></div>
                                <div class="control-streaming"></div>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <mat-chip-set>
                                @for (group of groupByTopic(element.documents) | keyvalue; track group.key;) {
                                <mat-basic-chip (click)="toggleSourceTopic(element.id + group.key)" matBadgePosition="above after" [matBadge]="castToRetrievedDocument(group.value).length">{{mapTopicName((activeGroup$ | async)?.topics || [], group.key) }}</mat-basic-chip>
                            }
                            </mat-chip-set>
                            @for (group of groupByTopic(element.documents) | keyvalue; track group.key) {
                                <div [id]="element.id + group.key" [hidden]="visibleSourceTopic !== element.id + group.key" >
                                    <mat-nav-list>
                                @for(source of castToRetrievedDocument(group.value); track source; let idx = $index) {
                                    <a mat-list-item href="{{source.topic == 'AD_HOC_UPLOAD' ? '#' : source.url}}" [target]="source.topic == 'AD_HOC_UPLOAD' ? '_self' : '_blank'"><div *ngIf="source.topic !== 'AD_HOC_UPLOAD'" matListItemMeta><mat-icon>open_in_new</mat-icon></div>{{(idx+1) + ". " + source.title}}</a>
                                }
                                    </mat-nav-list>
                                </div>

                            }
                        </mat-card-footer>
                    </mat-card>
                }
                <mat-card appearance="outlined" class="chat-llm-answer-card" *ngIf="(transientResponse$ | async)?.ongoing" >
                    <mat-card-content class="chat-llm-answer">
                        <app-streaming-container [stream]="transientResponse$" [hidden]="false"></app-streaming-container>
                        <div class="chat-llm-answer-controls">
                            <div class="control-streaming"><mat-spinner diameter="16" strokeWidth="2"></mat-spinner></div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
        <div class="chat-footer-container">
            <button mat-icon-button
                    (click)="addFile()"
                    [matBadge]="(conversationDetail$ |async)?.documents?.length"
                    [matBadgeHidden]="(conversationDetail$ |async)?.documents?.length == 0"
                    [disabled]="((activeConversationId$ | async) ? false : true) || (appBusyState$ | async) || false"

            >
                <mat-icon>attach_file_add</mat-icon>
            </button>
            <div #topicList class="topic-list">
            <mat-chip-listbox multiple [disabled]="(appBusyState$ | async) || false">
                @for (topic of (activeGroup$ | async)?.topics; track topic) {
                    <mat-chip-option *ngIf="topic.key !== 'AD_HOC_UPLOAD'" (selectionChange)="toggleTopic($event, topic.key)" [selected]="topic.defaultEnabled">{{topic.name}}</mat-chip-option>
                }
            </mat-chip-listbox>
            </div>

            <div class="chat-question-field">
            <mat-form-field subscriptSizing="dynamic" class="chat-input" appearance="outline">
                <textarea
                        autofocus
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="20"
                        class="chat-input-textarea"
                        placeholder="{{'CONV.ASK_PLACEHOLDER' | translate}}"
                        matInput
                        maxlength="5000"
                        #inputMessage
                        [disabled]="((activeConversationId$ | async) ? false : true) && (appBusyState$ | async) || false"
                        [(ngModel)]="message"
                        (keyup.enter)="addUserPrompt(message)"
                ></textarea>
                <span *ngIf="message.length > showLengthIndicatorAfter" class="remaning">{{message.length}}/{{ maxMessageLength }}</span>
            </mat-form-field>
                <div class="chat-disclaimer-container"
                     matTooltip="{{ 'CONV.DISCLAIMER' | translate }}"
                     matTooltipShowDelay="2000"
                     matTooltipHideDelay="500"
                >{{ 'CONV.DISCLAIMER' | translate }}</div>
            </div>

            <button
                    mat-icon-button
                    color="primary"
                    [disabled]="((activeConversationId$ | async) ? false : true) || (appBusyState$ | async) || false"
                    (click)="addUserPrompt(message);">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

