import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {LoginFailedComponent} from "./components/login-failed/login-failed.component";
import {ChatComponent} from "./components/chat/chat.component";

const routes: Routes = [
  {
    path: '',
    component: ChatComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: 'enabledNonBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
