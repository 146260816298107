import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RestService {
  constructor(
      private _httpClient: HttpClient
  ) { }

  get<T> (url: string, options: any = {}, subNode: string = ''): Observable<T> {
    return this._httpClient.get<T>(environment.apiBase + url, options)
        .pipe(
            map((data: any) => subNode ? data[subNode] : data),
        )
  }

  delete<T> (url: string, options: any = {}, subNode: string = ''): Observable<T> {
    return this._httpClient.delete<T>(environment.apiBase + url, options)
        .pipe(
            map((data: any) => subNode ? data[subNode] : data),
        )
  }

  post<T> (url: string, body: any, options: any = {}): Observable<T> {
    return this._httpClient.post<T>(environment.apiBase + url, body, options)
        .pipe(
            map((data: any) => data)
        )
  }

    put<T> (url: string, body: any, options: any = {}): Observable<T> {
        return this._httpClient.put<T>(environment.apiBase + url, body, options)
            .pipe(
                map((data: any) => data)
            )
    }

}
