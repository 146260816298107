<div class="upload-header">
    <mat-icon>attach_file_add</mat-icon>
    <div class="upload-title">{{ 'FILE.DIALOG_TITLE' | translate }}</div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button></div>
<div class="upload-panel">
    <div class="center">
        <div class="infotext" markdown>{{'FILE.INFOTEXT' | translate : { value: 2 - ((conversationDetail$ | async)?.documents?.length || 0), total: 2 } }}</div>
        <file-upload [multiple]="true"
                        [accept]="'application/pdf'"
                     [control]="fileUploadControl"

        >
            <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                <file-upload-drop-zone>
                    @if (isFileDragDropAvailable) {
                        {{'FILE.DROPZONE' | translate}}

                    } @else {
                        {{'FILE.NO_DROP' | translate}}
                    }
                </file-upload-drop-zone>
            </ng-template>
        </file-upload>
        <div class="upload-table-container">
            <table class="upload-table" *ngIf="fileUploadControl.value.length > 0 || ((conversationDetail$ | async)?.documents?.length || 0) > 0">
                <thead>
                <tr>
                    <th>{{ "FILE.NAME" | translate }}</th>
                    <th>{{"FILE.CLASSIFICATION.TITLE" |translate }}</th>
                    <th>{{"FILE.ACTION" |translate}}</th>
                </tr>
                </thead>
                <tbody class="upload-table">
                    @for (item of (conversationDetail$ | async)?.documents; track item.id; let idx = $index) {

                    <tr>
                    <td class="filename-cell">{{ item.title }}</td>
                    <td>{{'FILE.CLASSIFICATION.' + item.classification.toUpperCase() | translate}}</td>
                    <td>
                        <button mat-icon-button (click)="deleteFile(item.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
                    }
                <tr *ngFor="let item of fileUploadControl.value; let i=index">
                    <td class="filename-cell">{{ item.name }}</td>
                    <td>
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>{{"FILE.CLASSIFICATION.TITLE" |translate }}</mat-label>
                            <mat-select required #select>
                                <mat-option value="public">{{"FILE.CLASSIFICATION.PUBLIC" |translate }}</mat-option>
                                <mat-option value="internal">{{"FILE.CLASSIFICATION.INTERNAL" |translate }}</mat-option>
                                <mat-option value="confidential">{{"FILE.CLASSIFICATION.CONFIDENTIAL" |translate }}</mat-option>
                                <mat-option value="secret">{{"FILE.CLASSIFICATION.SECRET" |translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        </td>
                    <td>
                        <button mat-icon-button (click)="uploadFile(item, select.value)" [disabled]="['public','internal'].indexOf(select.value) < 0">
                            <mat-icon>upload</mat-icon>
                        </button>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>