import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {AppModule} from "./app.module";
import {AccountInfo} from "@azure/msal-browser";
import {filter, Observable, pipe, take} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {Store} from "@ngrx/store";
import {GroupActions} from "./redux/actions/group.actions";
import {State} from "./redux/reducers";
import {AppActions} from "./redux/actions/app.actions";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import { version, name } from '../../package.json';
import {LocalStorageService} from "./service/local-storage.service";
import {Group} from "./model/group.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit{
  @HostBinding('class')
  currentTheme: 'light-theme' | 'dark-theme' = 'light-theme';
  isDarkMode:boolean = false;

  @Input() sidenav!: MatSidenav
  account: Observable<AccountInfo | null>
  title = 'BKW AI Platform';
  groupsLoading$ =  this.store.select((state: State) => state.groups.loading)
  groups$ = this.store.select((state: State) => state.groups.groups)
  activeGroupId$ = this.store.select((state: State) => state.groups.activeGroupId)
  userAvatar$ = this.store.select((state: State) => state.app.userAvatar)
  viewMode$ = this.store.select((state: State) => state.app.viewMode);
  appTitle$ = this.store.select((state: State) => state.app.title);
  protected version: string;
  protected appName: string;
  protected langs = ['de', 'fr', 'en']
  constructor(private app: AppModule, private localStore: LocalStorageService, private store: Store<State>, protected translate: TranslateService, protected titleService: Title) {
    let browserLang = navigator.language || 'en'
    browserLang = localStore.getUserLanguage() || (this.langs.includes(browserLang.substring(0,2).toLowerCase()) ? browserLang.substring(0,2).toLowerCase() : 'en')
    this.setLang(browserLang)
    this.account = app.account
    this.version = version;
    this.appName = name;
  }

  setLang(lang: string) {
    this.translate.use(lang)
    this.localStore.setUserLanguage(lang)
  }

  ngOnInit(): void {
    this.account.pipe(filter((account): account is AccountInfo => !!account)).subscribe(a => {
      this.store.dispatch(AppActions.appLoginSuccess({userAccount: a}));
    })

    this.store.dispatch(GroupActions.groupsLoad())
    this.appTitle$.subscribe(title => this.titleService.setTitle(title))
  }

  toggleConversationDrawer() {
    this.store.dispatch(AppActions.appConverationDrawerToggle())
  }

  ngAfterViewInit() {

  }

  showComplianceDialog() {
    this.groups$.subscribe(groups => {
      this.app.showComplianceDialog(groups)
    })

  }

}
