import {isDevMode, NgModule, SecurityContext} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MsalBroadcastService, MsalInterceptor, MsalModule, MsalService} from "@azure/msal-angular";
import {
  AccountInfo,
  BrowserCacheLocation,
  InteractionStatus,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {filter, map, Observable} from "rxjs";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {LoginFailedComponent} from './components/login-failed/login-failed.component';
import {ChatComponent} from './components/chat/chat.component';
import {MatInputModule} from "@angular/material/input";
import {TextFieldModule} from "@angular/cdk/text-field";
import {MatCardModule} from "@angular/material/card";
import {MatChipsModule} from "@angular/material/chips";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {Store, StoreModule} from '@ngrx/store';
import {metaReducers, reducers, State} from './redux/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import { EffectsModule } from '@ngrx/effects';
import {GroupEffects} from "./redux/effects/group.effects";
import {ConversationsEffects} from "./redux/effects/conversations.effects";
import { DateAgoPipe } from './pipes/date-ago.pipe';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatBadgeModule} from "@angular/material/badge";
import {
    MatAccordion, MatExpansionModule,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelTitle
} from "@angular/material/expansion";
import { FeedbackComponent } from './components/feedback/feedback.component';
import {SafePipe} from "safe-pipe";
import { OrderByPipe } from './pipes/order-by.pipe';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MarkdownModule} from "ngx-markdown";
import {NgxFileDropModule} from "ngx-file-drop";
import {MatOptgroup, MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import { EditTitleComponent } from './components/edit-title/edit-title.component';
import {AppEffects} from "./redux/effects/app.effects";
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import {AppActions} from "./redux/actions/app.actions";
import {FileUploadModule} from "@iplab/ngx-file-upload";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import {ComplianceDialogComponent} from "./components/compliance-dialog/compliance-dialog.component";
import {GroupActions} from "./redux/actions/group.actions";
import {Router} from "@angular/router";
import {LocalStorageService} from "./service/local-storage.service";
import {Group} from "./model/group.model";
import { AddConversationButtonComponent } from './components/add-conversation-button/add-conversation-button.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFailedComponent,
    ChatComponent,
    DateAgoPipe,
    FeedbackComponent,
      OrderByPipe,
      FileUploadComponent,
      EditTitleComponent,
      ErrorDialogComponent,
      SafeHtmlPipe,
      ComplianceDialogComponent,
      AddConversationButtonComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
            auth: {
                clientId: environment.msalConfig.auth.clientId,
                authority: environment.msalConfig.auth.authority,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true, // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    piiLoggingEnabled: false,
                    logLevel: LogLevel.Verbose
                }
            }
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ['user.read']
            },
            loginFailedRoute: "/login-failed"
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map<string, Array<string>>([["https://graph.microsoft.com/v1.0/me", ["user.read"]], [environment.apiBase, [environment.apiScope]]])
        }),
        MatIconModule,
        MarkdownModule.forRoot({
            sanitize: SecurityContext.NONE
        }),
        MatDialogModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatInputModule,
        TextFieldModule,
        MatCardModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        FormsModule,
        MatProgressBarModule,
        MatSidenavModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatBadgeModule,
        MatExpansionModule,
        SafePipe,
        ClipboardModule,
        MatSnackBarModule,
        NgxFileDropModule,
        FileUploadModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers, {metaReducers}),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([GroupEffects, ConversationsEffects, AppEffects]),
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelTitle,
        MatExpansionPanelDescription,
        MatProgressSpinner,
        MatTooltipModule,
        MatOptgroup,
        MatTableModule, MatSelect, MatOption, BrowserAnimationsModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true},
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, private store: Store<State> , private dialog: MatDialog, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private localStore: LocalStorageService) {
      const tc = localStore.getTermsConditionsConsentVersion()
      if (tc){
          store.dispatch(AppActions.appAcknowledgeComplianceDialog({version: tc}))
      }
  }

    errors$ = this.store.select((state: State) => state.app.errors)
    complianceAcknowledged$ = this.store.select((state: State) => state.app.complianceVersionAcknowledged)
    groups$ = this.store.select((state: State) => state.groups.groups);
    noGroupPermissions$ = this.store.select((state: State) => state.groups.noGroupPermissions);
    currentTCVersion = 1;

    public account: Observable<AccountInfo | null> = this.msalBroadcastService
    .inProgress$
    .pipe(
      filter(status => status == InteractionStatus.None),
      map(() => {
        const instance: IPublicClientApplication = this.authService.instance;
        const activeAccount: AccountInfo | null = instance.getActiveAccount();
        const accounts: AccountInfo[] = instance.getAllAccounts();
        if (activeAccount != null) return activeAccount;
        if (accounts.length > 0) {
          const [firstAccount] = accounts;
          instance.setActiveAccount(firstAccount);
          return firstAccount;
        }
        return null;
      })
    );

    private error = this.errors$.subscribe((errors) => {
        if (errors.length > 0) {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
                data: errors
            });
            dialogRef.afterClosed().subscribe(result => {
                this.store.dispatch(AppActions.appClearErrors())
            });
        }
    });

    public showComplianceDialog(groups: Group[]) {
        let dialogRef = this.dialog.open(ComplianceDialogComponent, {
            data: groups,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.store.dispatch(AppActions.appAcknowledgeComplianceDialog({version: this.currentTCVersion}))
            this.localStore.setTermsConditionsConsentVersion(this.currentTCVersion)

        });
    }

    private compliance = this.groups$.subscribe((groups) => {
        if (groups.length > 0) {
            this.complianceAcknowledged$.subscribe((acknowledged) => {
                if (!acknowledged) {
                    this.showComplianceDialog(groups)
                }
                //activate default group
                let defaultGroup = groups.filter((group) => group.default)[0];
                this.store.dispatch(GroupActions.groupsActivate({groupId: defaultGroup.id, groupName: defaultGroup.name}));
            });
        }
    });

    private noGroupPermissions = this.noGroupPermissions$.subscribe((noGroupPermissions) => {
        if (noGroupPermissions) {
            this.router.navigate(['/login-failed']);
        }
    });

}
