<div class="main-content">
<mat-toolbar>
  <mat-toolbar-row >
      <button mat-icon-button (click)="toggleConversationDrawer()"><mat-icon>menu</mat-icon></button>
    <div>
      <img class="logo" src="img/BKW_Logo_RGB_S.png" width="142" height="64" />
      </div>
    <div class="app-title">
        {{ appTitle$ | async }}
    </div>
    <span style="flex: 1 1 auto;"></span>
    <div layout="row" layout-align="center center">
        <span class="user-name" *ngIf="(viewMode$ |async) == 'desktop'">{{(account | async)?.name }}</span><img class="avatar-img" [src]="(userAvatar$ | async)"/>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="more-menu" aria-label="Example icon-button with a menu">
         <mat-icon iconPositionEnd>more_vert</mat-icon>
      </button>


        <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item [matMenuTriggerFor]="groups">{{ 'GROUP.TITLE' | translate }}</button>
            <mat-menu #groups="matMenu">
            @for (item of groups$ | async ; track item.id) {
                <button mat-menu-item><mat-icon *ngIf="item.id == (activeGroupId$ | async)">check_small</mat-icon>{{ item.name }}</button>
            } @empty {
                <li>{{ "GROUP.NO_GROUP" | translate }}</li>
            }
            </mat-menu>
            <button mat-menu-item [matMenuTriggerFor]="languages">{{ 'LANG.TITLE' | translate }}</button>
            <mat-menu #languages="matMenu">
            @for (lang of langs ; track lang) {
                <button mat-menu-item (click)="setLang(lang)"><mat-icon *ngIf="(translate.currentLang || translate.defaultLang) == lang">check_small</mat-icon>{{ 'LANG.' + lang | translate }}</button>
            }
            </mat-menu>
            <button mat-menu-item (click)="showComplianceDialog()">{{ 'COMPLIANCE.TC' | translate }}</button>
            <div class="ui-version">ui: v{{version}}</div>
        </mat-menu>
    </div>
    </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>

</div>


