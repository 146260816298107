<div class="compliance-header">
    <mat-icon>assured_workload</mat-icon>
    <div class="compliance-title">{{ 'COMPLIANCE.TITLE' | translate }}</div>
    </div>
<div class="compliance-panel">
    <div class="center" [innerHtml]=" 'COMPLIANCE.TEXT' | translate | safeHtml ">

    </div>
</div>
<mat-dialog-actions>
    <button mat-raised-button  [mat-dialog-close]="true">{{ "GENERIC.ACKNOWLEDGE" | translate }}</button>
</mat-dialog-actions>