import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {Conversation, ConversationStub} from "../../model/conversation.model";
import {ChatMessage} from "../../model/chat-message";
import {FileSystemFileEntry} from "ngx-file-drop";
import {BackendError} from "../../model/error-message.model";

export const ConversationsActions = createActionGroup({
  source: 'Conversations',
  events: {
    'Conversations Load': props<{groupId: string}>(),
    'Conversations Load Success': props<{ data: ConversationStub[], latestConversationId?: string }>(),
    'Conversations Load Failure': props<{ error: BackendError }>(),
    'Conversation New': props<{groupId: string}>(),
    'Conversation New Success': props<{conversation: ConversationStub}>(),
    'Conversation New Failure': props<{error: BackendError}>(),
    'Conversation Activate': props<{ conversationId: string }>(),
    'Conversation Delete': props<{ conversationId: string }>(),
    'Conversation Detail Load': props<{ conversationId: string }>(),
    'Conversation Detail Load Success': props<{ data: Conversation }>(),
    'Conversation Detail Load Failure': props<{ error: BackendError }>(),
    'Conversation Detail Add Question': props<{ question: string }>(),
    'Conversation Detail Add Response Chunk': props<{ response_chunk: ChatMessage }>(),
    'Conversation Detail Add Final Response': props<{ response: ChatMessage }>(),
    'Conversation Detail Add Response Failure': props<{ error: BackendError }>(),
    'Conversation Upload File': props<{ conversationId: string, file: FormData, classification: string }>(),
    'Conversation Upload File Success': props<{ conversationId: string, fileId: string }>(),
    'Conversation Upload File Failure': props<{ error: BackendError }>(),
    'Conversation Delete File Success': props<{ fileId: string }>(),
    'Conversation Delete File': props<{ conversationId: string, fileId: string }>(),
    'Conversation Delete File Failure': props<{ error: BackendError }>(),
    'Conversation Rename': props<{ conversationId: string, newTitle: string }>(),
    "Conversation Rename Success": props<{ conversationId: string, newTitle: string }>(),
    "Conversation Rename Failure": props<{ error: BackendError }>(),
    "Deactivate Active Conversation": emptyProps()

  }
});
