import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GroupActions} from "../actions/group.actions";
import {catchError, filter, find, map, of, pipe, switchMap} from "rxjs";
import {RestService} from "../../service/rest.service";
import {Group} from "../../model/group.model";
import {ConversationStub} from "../../model/conversation.model";
import {ConversationsActions} from "../actions/conversations.actions";


@Injectable()
export class GroupEffects {


  constructor(private actions$: Actions, private restService: RestService) {}

  loadGroups$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.groupsLoad),
    switchMap(() => this.restService.get<Group[]>("/v0/user/group-accesses").pipe(
      map(groups => {
          if (groups.length === 0) {
              return GroupActions.groupsEmpty();
          } else {
              return GroupActions.groupsLoadSuccess({data: groups})
          }
      }),
      catchError(error => of(GroupActions.groupsLoadFailure({error})))
    ))
  ));



    setDefaultConversation = createEffect(() => this.actions$.pipe(
        ofType(GroupActions.groupsActivate),
        switchMap((groupId) => this.restService.get<ConversationStub[]>(`/v0/conversation/${groupId.groupId}/conversation-list`).pipe(
            map(conversations => {
                let latestConversation = this.findLatestEmptyConversation(conversations);
                return ConversationsActions.conversationsLoadSuccess({data: conversations, latestConversationId: latestConversation?.id})
                }
            ),
            catchError(error => {
                return of(ConversationsActions.conversationsLoadFailure({error}));})
        ))
    ));

    findLatestEmptyConversation(conversations: ConversationStub[]): ConversationStub | undefined {
        if (conversations == undefined || conversations.length == 0) {
            return undefined;
        }
        conversations.sort((a,b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());
        if(conversations[0].message_count !== 0 && new Date(conversations[0].modified_at).getTime() < new Date().getTime() - 1000 * 60 * 60 * 4) {
            return undefined;
        } else {
            return conversations[0];
        }
    }

}
