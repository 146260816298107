<mat-dialog-content>
    <mat-form-field subscriptSizing="dynamic" class="chat-input" appearance="outline" style="width: 100%">
        <mat-label>{{"CONV.NEW_TITLE" | translate}}</mat-label>
        <input matInput [(ngModel)]="title" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancel()">{{ "GENERIC.CANCEL" | translate }}</button>
    <button mat-button [mat-dialog-close]="title()" autofocus>{{ "GENERIC.OK" | translate }}</button>
</mat-dialog-actions>
