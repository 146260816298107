{
  "name": "ai-platform-frontend",
  "version": "1.6.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:production": "ng build --aot --optimization --progress --configuration=production",
    "build:staging": "ng build --aot --optimization --progress --configuration=staging"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.2",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.0.2",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@azure/msal-angular": "^3.0.19",
    "@azure/msal-browser": "^3.16.0",
    "@azure/static-web-apps-cli": "^1.1.8",
    "@iplab/ngx-file-upload": "^18.0.0",
    "@microsoft/applicationinsights-web": "^3.3.0",
    "@ngneat/edit-in-place": "^1.9.0",
    "@ngrx/effects": "^18.0.1",
    "@ngrx/store": "^18.0.1",
    "@ngrx/store-devtools": "^18.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "bootstrap": "^5.3.3",
    "hammerjs": "^2.0.8",
    "icepick": "^2.4.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-markdown": "^18.0.0",
    "ngx-sse-client": "^17.0.0",
    "primeng": "^17.18.5",
    "rxjs": "~7.8.0",
    "safe-pipe": "^3.0.4",
    "socket.io": "^4.7.5",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.0",
    "@angular/localize": "^18.0.2",
    "@ngrx/schematics": "^18.0.1",
    "@types/icepick": "^2.3.3",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^10.0.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  },
  "engines": {
    "node": ">=18.0.0"
  }
}
