import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly termsConditionsConsentVersionKey = 'termsConditionsConsentVersion';
  private readonly userLanguageKey = 'userLanguage';

  constructor() {
  }

  // Getters
  getTermsConditionsConsentVersion(): number | null {
    return Number.parseInt(localStorage.getItem(this.termsConditionsConsentVersionKey) || '0');
  }

  getUserLanguage(): string | null {
    return localStorage.getItem(this.userLanguageKey);
  }

  // Setters
  setTermsConditionsConsentVersion(version: number): void {
    localStorage.setItem(this.termsConditionsConsentVersionKey, version.toString());
  }

  setUserLanguage(lang: string){
    return localStorage.setItem(this.userLanguageKey, lang);
  }

}
