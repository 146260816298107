import {ChatMessage} from "./chat-message";
import {Subject} from "rxjs";

export class ChatConversation {
    messages: ChatMessage[]
    subject: Subject<ChatMessage>
    constructor(messages: ChatMessage[] = []){
        this.messages = messages
        this.subject = new Subject<ChatMessage>()
    }

    addMessage(message: ChatMessage){
        this.messages.push(message)
        this.subject.next(message)
    }


}
