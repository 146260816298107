import {Component, inject} from '@angular/core';
import {EditTitleDialogData} from "../chat/chat.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ErrorMessage} from "../../model/error-message.model";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss'
})
export class ErrorDialogComponent {
  protected info = false;
  readonly data = inject<ErrorMessage[]>(MAT_DIALOG_DATA);

  toggleInfo() {
    this.info = !this.info;
  }
}

