import {Component, inject} from '@angular/core';
import {ErrorMessage} from "../../model/error-message.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Group} from "../../model/group.model";


@Component({
  selector: 'app-compliance-dialog',
  templateUrl: './compliance-dialog.component.html',
  styleUrl: './compliance-dialog.component.scss'
})
export class ComplianceDialogComponent {
  readonly data = inject<Group>(MAT_DIALOG_DATA);

}
