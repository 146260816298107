import { AccountInfo } from '@azure/msal-browser';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {SafeUrl} from "@angular/platform-browser";

export const AppActions = createActionGroup({
  source: 'App',
  events: {
    'App Conversation Drawer Open': emptyProps(),
    'App Conversation Drawer Close': emptyProps(),
    'App Converation Drawer Toggle': emptyProps(),
    'App Tips Panel Open': emptyProps(),
    'App Tips Panel Close': emptyProps(),
    'App Tips Panel Toggle': emptyProps(),
    'App Login Success': props<{userAccount: AccountInfo}>(),
    'App Avatar Load Success': props<{userAvatar: SafeUrl}>(),
    'App Avatar Load Failure': emptyProps(),
    'App Update View Mode': props<{viewMode: string}>(),
    'App Clear Errors': emptyProps(),
    'App Display Compliance Dialog': emptyProps(),
    'App Acknowledge Compliance Dialog': props<{version: number}>(),

  }
});
