import {AfterContentInit, Component, ElementRef, inject, model, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EditTitleDialogData} from "../chat/chat.component";


@Component({
  selector: 'app-edit-title',
  templateUrl: './edit-title.component.html',
  styleUrl: './edit-title.component.scss'
})
export class EditTitleComponent implements AfterContentInit{
  ngAfterContentInit(): void {
      this.titleInput?.nativeElement.select();
  }

  @ViewChild('titleInput')
    titleInput?: ElementRef<HTMLInputElement>;

  readonly dialogRef = inject(MatDialogRef<EditTitleComponent>);
  readonly data = inject<EditTitleDialogData>(MAT_DIALOG_DATA);
  readonly title = model(this.data.title);

  cancel(): void {
    this.dialogRef.close();
  }

}
