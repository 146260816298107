import {createReducer, on} from '@ngrx/store';
import {GroupActions} from '../actions/group.actions';
import {Group, Topic} from "../../model/group.model";
import {State} from "./index";

export const groupFeatureKey = 'group';

export interface GroupState {
    groups: Array<Group>;
    loaded: boolean;
    loading: boolean;
    hasActiveGroup: boolean;
    activeGroup?: Group;
    activeGroupId?: string;
    noGroupPermissions: boolean;
    activeTopics: Array<string>;
}

export const initialState: GroupState = {
    groups: [],
    loaded: false,
    loading: false,
    hasActiveGroup: false,
    noGroupPermissions: false,
    activeTopics: []
};

export const reducer = createReducer(
  initialState,
    on(GroupActions.groupsLoad, state => ({...state, loading: true})),
    on(GroupActions.groupsLoadSuccess, (state, {data}) => ({...state, groups: data, loaded: true, loading: false})),
    on(GroupActions.groupsLoadFailure, (state, {error}) => ({...state, loading: false})),
    on(GroupActions.groupsActivate, (state, activation) => (
        {...state, activeGroupId: activation.tenant.id, activeGroup: state.groups.filter(group => group.id === activation.tenant.id)[0], hasActiveGroup: true})
    ),
    on(GroupActions.groupsEmpty, (state) => ({...state, noGroupPermissions: true})),
    on(GroupActions.groupsActivateTopic, (state, {topicId}) => ({...state, activeTopics: [...state.activeTopics.filter(t => t !== topicId), topicId]})),
    on(GroupActions.groupsDeactivateTopic, (state, {topicId}) => ({...state, activeTopics: [...state.activeTopics.filter(t => t !== topicId)]})),
    );

export const selectGroups = (state: GroupState) => state.groups;
export const selectActiveGroupId = (state: State) => state.groups.activeGroupId;
export const activeGroup = (state: State) => state.groups.activeGroup;
export const activeTopics = (state: State) => state.groups.activeTopics;


