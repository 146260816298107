import {AfterContentInit, Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {RestService} from "../../service/rest.service";
import {combineLatest, map, take} from "rxjs";
import {ConversationsActions} from "../../redux/actions/conversations.actions";
import {FileUploadControl} from "@iplab/ngx-file-upload";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {

    protected conversationDetail$ = this.store.select((state: State) => state.conversations.activeConversationDetail)

    public fileUploadControl = new FileUploadControl({ listVisible: false }, []);

    constructor(private store: Store<State>, private restService: RestService) {
        this.conversationDetail$.subscribe(conv => {
                this.fileUploadControl.enable(conv.documents.length < 2);
                console.log(this.fileUploadControl.disabled);
            }
        );
    }




    uploadFile(file: File, classification: string){
        combineLatest([
            this.store.select((state: State) => state.groups.activeGroupId),
            this.store.select((state: State) => state.conversations.activeConversationId),
        ]).pipe(take(1)).subscribe(([groupId, conversationId]) => {
            const formData = new FormData();
            formData.append('file', file, file.name);
            this.store.dispatch(ConversationsActions.conversationUploadFile({conversationId: conversationId || '', file: formData, classification: classification}));
            this.fileUploadControl.removeFile(file);
        })

    }

    deleteFile(id: string) {
        combineLatest([
            this.store.select((state: State) => state.groups.activeGroupId),
            this.store.select((state: State) => state.conversations.activeConversationId),
        ]).pipe(take(1)).subscribe(([groupId, conversationId]) => {
            this.store.dispatch(ConversationsActions.conversationDeleteFile({conversationId: conversationId || '', fileId: id}));
        });
    }


    protected readonly window = window;
}
