import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GroupActions} from "../actions/group.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {RestService} from "../../service/rest.service";
import {Group} from "../../model/group.model";
import {ConversationStub} from "../../model/conversation.model";
import {AppActions} from "../actions/app.actions";
import {ConversationsActions} from "../actions/conversations.actions";


@Injectable()
export class GroupEffects {


  constructor(private actions$: Actions, private restService: RestService) {}

  loadGroups$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.groupsLoad),
    switchMap(() => this.restService.get<Group[]>("/v0/user/group-accesses").pipe(
      map(groups => {
          if (groups.length === 0) {
              return GroupActions.groupsEmpty();
          } else {
              return GroupActions.groupsLoadSuccess({data: groups})
          }
      }),
      catchError(error => of(GroupActions.groupsLoadFailure({error})))
    ))
  ));

  updateAppTitle$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.groupsActivate),
    map(a => AppActions.appUpdateTitle({title: a.tenant.name}))
  ));

     getConversationList = createEffect(() => this.actions$.pipe(
         ofType(GroupActions.groupsActivate),
         switchMap((activation) => this.restService.get<ConversationStub[]>(`/v0/conversation/${activation.tenant.id}/conversation-list`).pipe(
             map(conversations => {
                 let latestConversation = this.findLatestConversation(conversations);
                 let requireNewConversation = this.findLatestEmptyConversation(conversations) === undefined;
                 return ConversationsActions.conversationsLoadSuccess({data: conversations, latestConversationId: latestConversation?.id, requireNewConversation: requireNewConversation});
                 }
             ),
             catchError(error => {
                 return of(ConversationsActions.conversationsLoadFailure({error}));})
         ))
     ));

    findLatestEmptyConversation(conversations: ConversationStub[]): ConversationStub | undefined {
        if (conversations == undefined || conversations.length == 0) {
            return undefined;
        }
        conversations.sort((a,b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());
        if(conversations[0].message_count !== 0 && new Date(conversations[0].modified_at).getTime() < new Date().getTime() - 1000 * 60 * 60 * 4) {
            return undefined;
        } else {
            return conversations[0];
        }
    }

    findLatestConversation(conversations: ConversationStub[]): ConversationStub | undefined {
        if (conversations == undefined || conversations.length == 0) {
            return undefined;
        }
        conversations.sort((a,b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());
        return conversations[0];

    }

}
