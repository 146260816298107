import {createReducer, on} from '@ngrx/store';
import {AppActions} from '../actions/app.actions';
import {AccountInfo} from "@azure/msal-browser";
import {SafeUrl} from "@angular/platform-browser";
import {ErrorMessage, ErrorMessageType} from "../../model/error-message.model";
import {ConversationsActions} from "../actions/conversations.actions";
import {GroupActions} from "../actions/group.actions";
import {LocalStorageService} from "../../service/local-storage.service";

export const appFeatureKey = 'app';

export interface AppState {
    conversationPanelOpen: boolean;
    tipsPanelOpen: boolean;
    userAccount?: AccountInfo;
    userAvatar?: SafeUrl;
    viewMode: string;
    errors: ErrorMessage[];
    title: string;
    complianceVersionAcknowledged: number;
}


export const initialState: AppState = {


    conversationPanelOpen: true,
    tipsPanelOpen: false,
    viewMode: "desktop",
    errors: [],
    title: "BKW AI Platform",
    complianceVersionAcknowledged: 0
};

export const reducer = createReducer(
  initialState,
    on(AppActions.appConversationDrawerOpen, state => ({...state, conversationPanelOpen: true})),
    on(AppActions.appConversationDrawerClose, state => ({...state, conversationPanelOpen: false})),
    on(AppActions.appTipsPanelOpen, state => ({...state, tipsPanelOpen: true})),
    on(AppActions.appTipsPanelClose, state => ({...state, tipsPanelOpen: false})),
    on(AppActions.appConverationDrawerToggle, state => ({...state, conversationPanelOpen: !state.conversationPanelOpen})),
    on(AppActions.appTipsPanelToggle, state => ({...state, tipsPanelOpen: !state.tipsPanelOpen})),
    on(AppActions.appLoginSuccess, (state, account) =>  ({...state, userAccount: account.userAccount})),
    on(AppActions.appAvatarLoadSuccess, (state, avatar) => ({...state, userAvatar: avatar.userAvatar})),
    on(AppActions.appUpdateViewMode, (state, viewMode) => ({...state, viewMode: viewMode.viewMode})),
    on(ConversationsActions.conversationUploadFileFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.UPLOAD_FILE_FAILED)]})),
    on(ConversationsActions.conversationNewFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CREATE_CONVERSATION_FAILED)]})),
    on(ConversationsActions.conversationRenameFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATION_RENAME_FAILED)]})),
    on(ConversationsActions.conversationsLoadFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATIONS_LOAD_FAILED)]})),
    on(ConversationsActions.conversationDetailAddResponseFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.CONVERSATION_ADD_RESPONSE_FAILED)]})),
    on(ConversationsActions.conversationDeleteFileFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.DELETE_FILE_FAILED)]})),
    on(GroupActions.groupsLoadFailure, (state, error) => ({...state, errors: [...state.errors, new ErrorMessage(error.error, ErrorMessageType.GROUP_LOAD_FAILED)]})),
    on(GroupActions.groupsActivate, (state, group) => ({...state, title: group.groupName})),
    on(AppActions.appAcknowledgeComplianceDialog, (state, version) => ({...state, complianceVersionAcknowledged: version.version})),






    on(AppActions.appClearErrors, (state) => ({...state, errors: []}))
    );

