import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {map, switchMap} from "rxjs";
import {AppActions} from "../actions/app.actions";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";
import {GroupActions} from "../actions/group.actions";
import {LoggingService} from "../../service/logging.service";

@Injectable()
export class AppEffects {


    constructor(private sanitizer: DomSanitizer, private logging: LoggingService, private actions$: Actions, private httpClient: HttpClient) {
    }

    loadGroups$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.appLoginSuccess),
        switchMap(() => this.httpClient.get("https://graph.microsoft.com/v1.0/me/photo/$value", {observe: 'response', responseType: 'blob'}).pipe(
            map(response => {
                if (response.body)
                    return AppActions.appAvatarLoadSuccess({userAvatar: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response.body))});
                else
                    return AppActions.appAvatarLoadFailure();
            }),
        ))
    ));

    toggleDrawer$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.appUpdateViewMode),
        map(mode => mode.viewMode == "desktop" ? AppActions.appConversationDrawerOpen() : AppActions.appConversationDrawerClose() )
    ));

    logPageImpression$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActions.groupsActivate),

        map(group => this.logging.logPageView(group.groupName))
    ),
        {dispatch: false});
}