import {Component, HostBinding} from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent{
  @HostBinding('class')
  currentTheme: 'light-theme' | 'dark-theme' = 'light-theme';






}
