import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener, NgZone,
    OnInit,
    ViewChild, AfterViewInit, ChangeDetectorRef
} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ChatConversation} from "../../model/chat-conversation";
import {MatDrawerMode} from '@angular/material/sidenav';
import {State} from "../../redux/reducers";
import {Store} from "@ngrx/store";
import {ConversationsActions} from "../../redux/actions/conversations.actions";
import {Clipboard} from '@angular/cdk/clipboard';
import {
    MatDialog
} from '@angular/material/dialog';
import {FeedbackComponent} from "../feedback/feedback.component";
import {FileUploadComponent} from "../file-upload/file-upload.component";
import {AppActions} from "../../redux/actions/app.actions";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditTitleComponent} from "../edit-title/edit-title.component";
import {MatMenu} from "@angular/material/menu";
import {MatChipSelectionChange} from "@angular/material/chips";
import {activeGroup, activeTopics} from "../../redux/reducers/group.reducer";
import {GroupActions} from "../../redux/actions/group.actions";
import {ChatMessage, RetrievedDocument} from "../../model/chat-message";
import {Topic} from "../../model/group.model";
import {ActivatedRoute, Router} from "@angular/router";
import {KatexOptions} from "ngx-markdown";
import {ConversationElement} from "../../model/conversation-element.model";
import {take} from "rxjs";

export interface EditTitleDialogData {
    conversationId: string;
    title: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  changeDetection: ChangeDetectionStrategy.Default

})
export class ChatComponent implements OnInit, AfterViewInit{
    protected intro_class:string[] = [];
    protected conversation: ChatConversation = new ChatConversation();
    response: ChatMessage;
    message: string = "";
    maxMessageLength = 5000;
    showLengthIndicatorAfter = 4000;
    protected conversations: string[] = ["Test conversation 1", "Test conversation with a long title", "Test conversation with an even longer title"];
    @ViewChild('autosize') autosize?: CdkTextareaAutosize;
    @ViewChild('conversationMenu') conversationMenu?: MatMenu;
    conversations$ = this.store.select((state: State) => state.conversations.conversations)
    streamingAnswer$ = this.store.select((state: State) => state.conversations.activeConversationDetail.messages.filter((message) => message.ongoing)[0])
    latestConversation$ = this.store.select((state: State) => state.conversations.latestConversationId)
    conversationDetail$ = this.store.select((state: State) => state.conversations.activeConversationDetail)
    activeConversationId$ = this.store.select((state: State) => state.conversations.activeConversationId)
    conversationDrawerState$ = this.store.select((state: State) => state.app.conversationPanelOpen)
    activeGroup$ = this.store.select(activeGroup)
    transientResponse$ = this.store.select((state: State) => state.conversations.transientResponse)
    appBusyState$ = this.store.select((state: State) => state.app.busy)
    @ViewChild('chatScrollContainer') private chatContainer!: ElementRef;
    @ViewChild('inputMessage') private inputMessage?: ElementRef<HTMLTextAreaElement> | undefined;
    @ViewChild('topicList') private topicList!: ElementRef<HTMLDivElement>;
    @ViewChild('streaming') private streaming!: ElementRef<HTMLDivElement>;
    drawerMode: MatDrawerMode = 'side';
    desktopViewWidth: number = 800;
    visibilityFilter = "all";
    public katexOptions: KatexOptions = {
        displayMode: true,
        throwOnError: false,
        errorColor: '#cc0000'
    };


    @HostListener('window:resize', ['$event.target.innerWidth'])
    onResize(width: number) {
        if (width >= this.desktopViewWidth) {
            this.drawerMode = 'side';
            this.store.dispatch(AppActions.appUpdateViewMode({viewMode: "desktop"}))
        } else {
            this.drawerMode = 'over';
            this.store.dispatch(AppActions.appUpdateViewMode({viewMode: "mobile"}))

        }

    }




    constructor(private ref: ChangeDetectorRef, private activeRoute: ActivatedRoute, private router: Router, private store: Store<State>, private clipboard: Clipboard, private snackBar: MatSnackBar, public dialog: MatDialog, private ngZone: NgZone) {
        this.response = {ongoing: false};
    }
    ngOnInit() {

        this.onResize(window.innerWidth);
        this.activeRoute.params.subscribe(params => {
            this.activeGroup$.subscribe((activeGroup) => {
                if(activeGroup) {
                    this.latestConversation$.subscribe((conversation) => {
                        if(conversation != undefined) {
                            if (params['conversationId']) {
                                this.store.dispatch(ConversationsActions.conversationActivate({conversationId: params['conversationId']}))
                            } else {
                                if (conversation.requireNew) {
                                    this.store.dispatch(ConversationsActions.conversationNew({groupId: activeGroup.id}))
                                } else{
                                    this.router.navigate(['conversation', conversation.id], {relativeTo: this.activeRoute.parent})

                                }

                            }
                        }
                    });
                }
            });
        });

    }

    ngAfterViewInit() {

        this.conversationDetail$.subscribe((conv) => {
        if (conv.messages.length == 0) {
            this.intro_class = ["chat-llm-answer-text"]
        } else {
            this.intro_class = ["chat-llm-answer-text"]
        }
        //focus on input when there are no open dialogs
        if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
            this.inputMessage?.nativeElement.focus();
        }
            this.activeConversationId$.subscribe(() => {
                setTimeout(() => {
                    this.scrollToBottom();
                },500);

            });

        });

    }
    castToRetrievedDocument(input: any){
        return input as RetrievedDocument[];
    }

    mapTopicName(topics: Topic[], key: any){
        return topics.filter((topic) => topic.key == key).map((topic) => topic.name)[0]
    }

    visibleSourceTopic  = "";
    toggleSourceTopic(topic: any){
        if(this.visibleSourceTopic == topic){
            this.visibleSourceTopic = "";
        }else{
            this.visibleSourceTopic = topic;
        }
    }

    sanitizeContent(input: string){
        let sanitized =  input ? input.replace(/(\\\[)/g,'\\\\\[') : input;
        sanitized =  sanitized ? sanitized.replace(/(\\\])/g,'\\\\]') : sanitized;
        sanitized =  sanitized ? sanitized.replace(/(\\\()/g,'\\\\(') : sanitized;
        sanitized =  sanitized ? sanitized.replace(/(\\\))/g,'\\\\)') : sanitized;

        return sanitized;
    }

    groupByTopic(collection: RetrievedDocument[]): any{
        if (collection == undefined) {
            return {};
        }
        const groupedResult =  collection.reduce((previous,current)=>{

            if(!previous[current.topic]){
                previous[current.topic] = [] as RetrievedDocument[];
            }

            previous[current.topic].push(current);
            return previous;
        },{} as any);
        return groupedResult
    }

    scrollToBottom = () => {
        try {
            this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
        } catch (err) {}
    }

    addUserPrompt(message: string){
        this.store.dispatch(ConversationsActions.conversationDetailAddQuestion({question: message}))
        this.transientResponse$.pipe(take(10)).subscribe((response) => {
            this.scrollToBottom();
        });
        this.message = "";
    }


    deleteConversation(e: Event, conversationId: string){
        e.preventDefault();
        e.stopImmediatePropagation();
        this.store.dispatch(ConversationsActions.deactivateActiveConversation())
        this.store.dispatch(ConversationsActions.conversationDelete({conversationId: conversationId}))

    }

    editTitle(e: Event, conversationId: string, current: string){
        e.preventDefault();
        const dialogRef = this.dialog.open(EditTitleComponent, {
            data: {conversationId: conversationId, title: current}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.store.dispatch(ConversationsActions.conversationRename({conversationId: conversationId, newTitle: result}))
            }
        });

    }

    topicCount = 0;
    toggleTopic(event: MatChipSelectionChange, topic: string) {
        if(event.selected){
            this.topicCount++;
            this.store.dispatch(GroupActions.groupsActivateTopic({topicId: topic}))

        }else {
            this.topicCount--;
            this.store.dispatch(GroupActions.groupsDeactivateTopic({topicId: topic}))
        }
    }


    addFile(){
        let dialogRef = this.dialog.open(FileUploadComponent, {
            panelClass: 'file-upload-panel',
            width: '700px',
            height: '700px',
            data: {conversation$: this.activeConversationId$}
        })
    }

    openFeedbackPanel() {
        let dialogRef = this.dialog.open(FeedbackComponent, {
            panelClass: 'feedback-panel',
            minWidth: '640px',
            minHeight: '700px'
        });
    }

    copyClipboard(text: string){
        const pending = this.clipboard.beginCopy(text);
        let remainingAttempts = 3;
        const attempt = () => {
            const result = pending.copy();
            if (!result && --remainingAttempts) {
                setTimeout(attempt);
            } else {
                this.snackBar.open("Copied to clipboard", "Dismiss", {duration: 2000})
                pending.destroy();
            }
        };
        attempt();
    }

    protected readonly length = length;

    elementKey(element: ConversationElement){
        return element.id+element.answer.length;
    }
}
