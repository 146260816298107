import * as uuid from 'uuid';

export enum ErrorMessageType {
    UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED',
    CREATE_CONVERSATION_FAILED = 'CREATE_CONVERSATION_FAILED',
    CONVERSATION_RENAME_FAILED = 'CONVERSATION_RENAME_FAILED',
    CONVERSATIONS_LOAD_FAILED = 'CONVERSATIONS_LOAD_FAILED',
    CONVERSATION_ADD_RESPONSE_FAILED = 'CONVERSATION_ADD_RESPONSE_FAILED',
    DELETE_FILE_FAILED = 'DELETE_FILE_FAILED',
    GROUP_LOAD_FAILED = 'GROUP_LOAD_FAILED',
}

export interface BackendError{
    detail: string;
}

export class ErrorMessage {

    id: string = uuid.v4();

    constructor(public orginialError: BackendError, public type: ErrorMessageType) {

    }
}
