import {isDevMode} from '@angular/core';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromGroup from './group.reducer';
import * as fromApp from './app.reducer';
import * as fromConversations from './conversations.reducer'


export interface State {
    groups: fromGroup.GroupState
    conversations: fromConversations.ConversationsState
    app: fromApp.AppState
}

export const reducers: ActionReducerMap<State> = {
    groups: fromGroup.reducer,
    conversations: fromConversations.reducer,
    app: fromApp.reducer
};


export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
