import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GroupActions} from "../actions/group.actions";
import {catchError, filter, map, of, pipe, switchMap, withLatestFrom} from "rxjs";
import {ConversationsActions} from "../actions/conversations.actions";
import {RestService} from "../../service/rest.service";
import {Conversation, ConversationStub} from "../../model/conversation.model";
import {Store} from "@ngrx/store";
import {State} from "../reducers";
import * as fromConversations from '../reducers/conversations.reducer';
import * as fromGroups from '../reducers/group.reducer';

import {Group} from "../../model/group.model";
import {SseService} from "../../service/sse.service";
import {ChatMessage, Document} from "../../model/chat-message";



@Injectable()
export class ConversationsEffects {


  constructor(private actions$: Actions, private restService: RestService, private sseService: SseService, private store: Store<State>) {}


    setActiveConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationsLoadSuccess),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        pipe(
        map(([data, groupId]) => {
            if (data.latestConversationId !== undefined) {
                return ConversationsActions.conversationActivate({conversationId: data.latestConversationId || ""})
            } else {
                return ConversationsActions.conversationNew({groupId: groupId || ""})
            }
        })
        )
        ));

    createConversationIfNoneExists = createEffect(() => this.actions$.pipe(

        ofType(ConversationsActions.conversationsLoadSuccess),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        pipe(
            filter(([conv, groupId]) => conv.data.length == 0),
            map (([conv, groupId]) => {
                return ConversationsActions.conversationNew({groupId: groupId ||""});
        }))
    ));

    deleteConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDelete),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.delete<Group[]>(`/v0/conversation/${groupId}/${data.conversationId}/delete`).pipe(
            map(groups => GroupActions.groupsLoadSuccess({data: groups})),
            catchError(error => of(GroupActions.groupsLoadFailure({error: error})))
        ))
    ));

    addConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationNew),
        switchMap((data) => this.restService.get<ConversationStub>(`/v0/conversation/${data.groupId}/new`).pipe(
            switchMap(
            conv => of(ConversationsActions.conversationNewSuccess({conversation: conv}), ConversationsActions.conversationActivate({conversationId: conv.id}))),
            catchError(error => of(ConversationsActions.conversationNewFailure({error: error})))
        ))
    ));

    loadConversationDetail = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationActivate),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.get<Conversation>(`/v0/conversation/${groupId}/${data.conversationId}`).pipe(
            map(
                conv => ConversationsActions.conversationDetailLoadSuccess({data: conv})),
            catchError(error => of(ConversationsActions.conversationNewFailure({error: error})))
        ))
    ));

    addQuestion = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDetailAddQuestion),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId), this.store.select(fromConversations.selectActiveConversationId), this.store.select(fromGroups.activeTopics)),
        switchMap ((data) =>  this.sseService.queryLLM(data[1] || "", data[2] || "", data[3], data[0].question).pipe(
            map((event: ChatMessage | ConversationStub) => {
                if ('ongoing' in event) {
                    //chat update
                    const message = event as ChatMessage;
                    if (message.ongoing) {
                        return ConversationsActions.conversationDetailAddResponseChunk({response_chunk: message})
                    } else {
                        return ConversationsActions.conversationDetailAddFinalResponse({response: message})
                    }

                } else {
                    //conversation update
                    return ConversationsActions.conversationRenameSuccess({conversationId: event.id, newTitle: event.title})
                }
            }
                )
        ))

    ));

    renameConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationRename),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.put<ConversationStub>(`/v0/conversation/${groupId}/${data.conversationId}/update?title=${data.newTitle}`, {}).pipe(
            map(conv => ConversationsActions.conversationRenameSuccess({conversationId: conv.id, newTitle: conv.title})),
            catchError(error => of(ConversationsActions.conversationRenameFailure({error: error})))
        ))
    ));

    uploadFile = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationUploadFile),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) =>
            this.restService.post<Document>(`/v0/upload/${groupId}/${data.conversationId}/ad_hoc/upload?classification=${data.classification}`, data.file)
        .pipe(
            map(
                doc => ConversationsActions.conversationUploadFileSuccess({conversationId: data.conversationId, fileId: doc.id})),
            catchError(error => of(ConversationsActions.conversationUploadFileFailure({error: error.error})))
        ))
    ));

    uploadFileSuccess = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationUploadFileSuccess, ConversationsActions.conversationUploadFileFailure, ConversationsActions.conversationDeleteFileSuccess),
        withLatestFrom(this.store.select(fromConversations.selectActiveConversationId)),
        switchMap(([data, conversationId]) =>
            of(ConversationsActions.conversationActivate({conversationId: conversationId || ""})))
    ));

    deleteFile = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDeleteFile),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.delete<Document>(`/v0/upload/${groupId}/${data.conversationId}/ad_hoc/${data.fileId}/delete`).pipe(
            map(doc => ConversationsActions.conversationDeleteFileSuccess({fileId: data.fileId})),
            catchError(error => of(ConversationsActions.conversationDeleteFileFailure({error: error.error}))
            )
        ))
    ));


}
