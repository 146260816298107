import {Injectable, NgZone} from "@angular/core";
import {environment} from "../../environments/environment";
import { SseClient } from 'ngx-sse-client';
import {Observable, Subject} from "rxjs";
import {ChatMessage} from "../model/chat-message";
import {ConversationStub} from "../model/conversation.model";

export interface MessageData {
    chunk_message?: string;
    final_message?: string;
    ongoing: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class SseService {

    constructor(private sseClient: SseClient, private _ngZone: NgZone) { }

    queryLLM(group: string, conversation: string, topics: Array<string>, message: string): Subject<ChatMessage | ConversationStub> {
        const passThrough = new Subject<ChatMessage | ConversationStub>();
        this.sseClient.stream(environment.apiBase + '/v0/conversation/' + group + '/sse/' + conversation + '?max_iterations=8&topics='+encodeURIComponent(topics.join(','))+'&question='+encodeURIComponent(message), { keepAlive: false, reconnectionDelay: 1_000, responseType: 'event' })
            .subscribe((event: Event) => {
                if (event.type == "chatUpdate") {
                    let chatMessage = JSON.parse((event as MessageEvent<string>).data) as ChatMessage;
                    this._ngZone.run(() => passThrough.next(chatMessage));
                }
                if (event.type == "conversationUpdate") {
                    let conversation = JSON.parse((event as MessageEvent<string>).data) as ConversationStub;
                    this._ngZone.run(() => passThrough.next(conversation));
                }
            });
        return passThrough;
    }

}