import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {Group} from "../../model/group.model";
import {GroupState} from "../reducers/group.reducer";
import {BackendError} from "../../model/error-message.model";

export const GroupActions = createActionGroup({
  source: 'Groups',
  events: {
    'Groups Load': emptyProps(),
    'Groups Load Success': props<{ data: Group[] }>(),
    'Groups Load Failure': props<{ error: BackendError }>(),
    'Groups Activate': props<{ tenant: Group}>(),
    'Groups Empty': emptyProps(),
    'Groups Activate Topic': props<{ topicId: string }>(),
    'Groups Deactivate Topic': props<{ topicId: string }>(),
  }
});
